'use client';
import styled, { css } from 'styled-components';
import { colors, gradients } from '@/styles';
import { TGradients } from './gradients';

const gradientText = ({ gradient = 'purple' }: { gradient: TGradients }) => css`
  background: ${gradients[gradient]};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const animatedGradientText = () => css`
  --speed: 6s;
  /* text-align: center; */

  // background: linear-gradient(to right,  #ce09ba, #28a4ec, #e07de8, #28a4ec, #ce09ba);
  background: linear-gradient(to right, #f87a7a, #f9947d, #f57ab1, #e07de8, #f57ab1, #f9947d, #f87a7a);
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine var(--speed) linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`;

const animatedGradientWrapper = (glow: boolean) => css`
  --border-angle: 0turn;

  /* height: 100%;
  position: relative;

  & > * {
    position: relative;
  } */

  &:not(.on) {
    z-index: 1;
  }

  &.on {
    border-radius: 8px;
    z-index: 0;

    & > * {
      border-radius: 5px;
      border: none;
    }

    --clr-1: #f87a7a;
    --clr-2: #f9947d;
    --clr-3: #f57ab1;
    --clr-4: #e07de8;
    --clr-5: #dbb2ed;
    --clr-6: #efb4c9;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: -3px;
      z-index: -1;

      background: conic-gradient(
        from var(--border-angle),
        var(--clr-1),
        var(--clr-2),
        var(--clr-3),
        var(--clr-4),
        var(--clr-5),
        var(--clr-6),
        var(--clr-1)
      );
      border-radius: inherit;
      background-position: center center;
      background-repeat: no-repeat;
      animation: bg-spin 2s linear infinite;
    }

    &:after {
      filter: ${glow ? 'blur(2rem)' : 'none'};
      opacity: 0.5;
    }

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
    &:hover {
      animation-play-state: paused;
    }
    @property --border-angle {
      syntax: '<angle>';
      inherits: true;
      initial-value: 0turn;
    }
  }
`;

const checkedImageBackground = () => css`
  background-color: var(--colors-secondary-light-gray-1);
  /* opacity: 0.2; */
  background-image: repeating-linear-gradient(
      45deg,
      var(--colors-secondary-light-gray-2) 25%,
      transparent 25%,
      transparent 75%,
      var(--colors-secondary-light-gray-2) 75%,
      var(--colors-secondary-light-gray-2)
    ),
    repeating-linear-gradient(
      45deg,
      var(--colors-secondary-light-gray-2) 25%,
      var(--colors-secondary-light-gray-1) 25%,
      var(--colors-secondary-light-gray-1) 75%,
      var(--colors-secondary-light-gray-2) 75%,
      var(--colors-secondary-light-gray-2)
    );
  background-position:
    0 0,
    10px 10px;
  background-size: 20px 20px;
`;

const lib = Object.assign({
  gradientText,
  animatedGradientText,
  animatedGradientWrapper,
  checkedImageBackground,
});

export default lib;
